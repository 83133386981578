import { navigate } from 'gatsby-link';
import { useEffect } from 'react';

export default (): void => {
  useEffect(() => {
    navigate('/');
  }, []);

  return null;
};
